//Colors
$higreen:#91bb5b;
$lgreen: #dfffcf;
$green:#009341;
$dgreen:#698533;
$white: #fff;
$black: #333;
$grey: #aaa;
$gray:#999;
$lgray:#ccc;
$dgray:#636363;
$orange:#F68E57;
$red:#C7001C;
$blue:#3879a3;
$vlgray:#f3f3f3;

//Clearfix
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}  
