
.premium_ui {
    @import "constants";  //Color definitions
    color:$black;

    a {
        color:inherit;
        text-decoration:none;
        &:hover {
            color:inherit;
            text-decoration:none;
        }
    }
    ul {
        padding:0;
        list-style:none;
    }
    li {
        padding:0;
    }
    h3 {
        background:none;
        font-family:"dinmedium";
        color:$black;
        font-size:20px;
        letter-spacing:normal;
        text-align:center;
        font-weight:normal;
        margin-top:0;
    }
    .row {
        @extend .cf;
    }
    p{
        line-height:24px;
        margin:1em 0;
        padding:0;
        font-size:16px;
        &.boxTitle {
            font-size:20px;
            margin:10px 0;
            font-family:"dinmedium";
            #delete_preset {
                float:right;
                cursor:pointer;
                color:$orange;
                img {
                    vertical-align:middle;
                    width:20px;
                    margin-right:5px;
                }
            }
        }
    }
    h1,h2 {
        font-family:"dinmedium";
        font-size:40px;
        margin:1em 0;
    }
    h1 {
        font-weight:bold;
    }
    h2 {
        letter-spacing: normal;
    }
    .half {
        box-sizing:border-box;
        padding-right:15px;
        display:inline-block;
        width:50%;
        position:relative;
        vertical-align:top;
        &._75 {
            width:75%;
        }
        &.right {
            &._75 {
                width:25%;
            }
            padding-left:15px;
            padding-right:0;
        }
        &._66 {
            width:33%;
        }
        &.right {
            &._66 {
                width:66%;
            }
            padding-left:15px;
            padding-right:0;
        }
    }
    #content-only h2 {
        margin:3em 0 0.8em;
        font-size:30px;
    }
    #content-right {
        display:none;
    }
    #breadcrumbs-outer {
        padding:0;
        height:0;
        display:none;
    }

    #content-only {
        border-radius:15px 15px 0 0;
        padding-top:20px;
        height:100%;
        font-family:DIN;
    }

    #content-and-menu {
        position:relative;
        height:1600px;
        &.withMenu {
            #content-only {
                border-radius:15px 15px 0 0;
            }
            #content-right {
                display:table-cell;
                position:absolute;
                right: 0;
                 z-index: 2;
                 height:100%;
                 box-shadow: -4px 0 3px #eaeaea;
            }
        }
    }
    #menuBarOpener {
        background:$higreen;
        color:$black;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
        display: block;
        font-size: 20px;
        line-height: 40px;
        position: absolute;
        right: -95px;
        text-align: center;
        top: 100px;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        vertical-align: bottom;
        width: 150px;
        box-shadow: 3px -2px 6px -1px #666;
        &.fixed {
            position:fixed;
            top:100px;
            left: calc(50% + 425px); //480 -75 + 20
        }
        &.withMenu {
            &.fixed {
                left: calc(50% + 660px);
            }
        }
    }
    #content-only ul.breadcrumbs+h2 {
        margin-top:24px;
    }
    ul.breadcrumbs {
        font-family:Arial, Helvetica, sans-serif;
        padding:0;
        margin:0;
        display:block;
        font-size:14px;
        color:$gray;
        .backLink {
            font-size:12px;
            padding:3px 10px;
            margin-right:10px;
            border:1px solid $lgray;
            border-radius:5px;
            svg{
                fill:$dgray;
                margin:0 6px 0 0;
                height:8px;
                width:8px;
            }
        }
        li {
            display:inline-block;
        }
    }

    #menu-space-holder {
        width:1px;
        visibility:hidden;
        display:none;
        height:300px;
        margin:40px 0;
    }

    table#packagesList, #has-services>table {
        border-collapse:collapse;
        width:100%;
        margin:30px 0 40px;
        font-size:15px;
        border:1px solid $lgray;
        text-align:left;
        line-height:normal;
        thead {
            font-size:14px;
            background:$lgray;
            text-transform:uppercase;
            color:$dgray;
            cursor:pointer;
            font-weight:normal;
            th {
                -moz-user-select: -moz-none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                -o-user-select: none;
                user-select: none;
                padding:15px;
                position:relative;
                &.sort {
                    &:before {
                        content:"";
                        border-top:7px solid $higreen;
                        border-left:8px solid transparent;
                        border-right:8px solid transparent;
                        left: -7px;
                        position: absolute;
                        top: 20px;
                    }
                    &.desc:before {
                        border-bottom:7px solid $higreen;
                        border-left:8px solid transparent;
                        border-top:none;
                        border-right:8px solid transparent;
                        top:22px;
                    }
                }
            }
        }
        tbody {
            font-family:Helvetica;
            font-size:15px;
            tr {
                border-bottom:1px solid $lgray;
                &:hover {
                    background:$lgreen;
                    .infoBox {
                        display:block;
                    }
                }
                td {
                    padding:15px;
                    position:relative;
                    a {
                        color:$blue;
                        font-weight:bold;
                    }
                    .blackTick {
                        vertical-align:middle;
                    }
                    .error {
                        color:red;
                        line-height: 26px;
                    }
                    &:last-child {
                        &:hover {
                            position:relative;
                            ul {
                                position:absolute;
                                top:50px;
                                display:block;
                                z-index:1;
                                left:15px;
                                }
                        }
                        ul {
                            display:none;
                            background:white;
                            border:2px solid $lgray;
                            text-transform:uppercase;
                            font-weight:bold;
                            font-size:14px;
                            font-family:"dinmedium";
                            li {
                                width:230px;
                                display:block;
                                padding:10px;
                                a {
                                    cursor:pointer;
                                    color:inherit;
                                    display:block;
                                }
                                img {
                                    margin-right: 4px;
                                    vertical-align: middle;
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
     #has-services>table {
        td:last-child, th:last-child {
            text-align:center;
        }
     }

     table#packagesList {
         td:first-child {
             span.shortened+span {
                 background:white;
                 border:1px solid $lgray;
                 top:0;
                 left:11px;
                 border-radius:5px;
                 top:17px;
                 padding:3px;
                 z-index:1;
                 position:absolute;
                 display:none;
             }
             &:hover {
                 span.shortened+span {
                     display:inline-block;
                 }
             }
         }
     }

    #actions {
        z-index:1;
        display:inline-block;
        margin:15px 0 0 20px;
        position:relative;
        ul{
            display:none;
            background:white;
            border:2px solid $lgray;
            text-transform:uppercase;
            font-weight:bold;
            font-size:14px;
            font-family:"dinmedium";
            li {
                width:150px;
                display:block;
                padding:10px;
                img {
                    margin-right: 4px;
                    vertical-align: middle;
                    width: 30px;
                }
            }
        }
        button:active {
            position:relative;
            top:4px;
            box-shadow:none;
        }
        &:hover {
            ul {
                display:block;
                position:absolute;
                left:0;
                top:100%;
            }
        }
    }

    #infoBox {
        display:none;
        background:white;
        border: 5px solid $higreen;
        font-size: 18px;
        padding: 0 18px;
        position: fixed;
        text-align: left;
        width: 320px;
        z-index: 2;
        height:290px;
        img {
            vertical-align:middle;
            margin-left:10px;
        }
        .value {
            display:none;
        }
        p {
            margin:18px 0;
            line-height: 20px;
            &.optionsLink {
                cursor:pointer;
                font-size:24px;
                color:$higreen;
                margin-bottom:10px;
                font-family:DIN;
                text-shadow:0 1px 0 $higreen;
                img {
                    vertical-align:middle;
                    margin-right:10px;
                }
            }
            .property {
                color:$higreen;
                font-family:DIN;
                text-shadow:0 1px 0 $higreen;
            }
        }
        &:before {
        content: " ";
        height: 0;
        left: -25px;
        margin-top: -20px;
        position: absolute;
        top: 50%;
        width: 0;
        border-bottom: 20px solid transparent;
        border-right: 20px solid $higreen;
        border-top: 20px solid transparent;
        }
        &.withInfo {
            img {
                display:none;
            }
            .value {
                display:inline;
            }
        }
    }

    .nonreseller-packages-list {
      .ph-deactivate-toggle-ui {
        display: none;
      }
    }

    .searchable-list {
        border:2px solid $higreen;
        overflow:hidden;
        position:relative;
        font-size:20px;
        font-family:"dinlight";
        .content {
            height:115px;
            overflow-y:scroll;
            > img {
                display:none;
            }
            ul {
                margin:0;
                padding:0;
                list-style:none;
                li {
                    cursor:default;
                    padding:5px;
                    &:hover {
                        background: $lgreen;
                    }
                    &.selected {
                        background:$lgreen;
                    }
                    &.withButton {
                        @extend .cf;
                        line-height:40px;
                    }
                }
            }
            button {
                display: inline-block;
                float: right;
                font-size: 18px;
                margin: 0 25px 0 0;
                padding: 6px 6px 4px;
                width:140px;
                text-align:left;
                img {
                    margin: -2px 8px 0 4px;
                    vertical-align: middle;
                    width: 30px;
                }
            }
        }

        input.search {
            border:none;
            color:$white;
            background: $higreen;
            padding:12px 12px 12px 65px;
            font-family:inherit;
            font-size:inherit;
            box-sizing:border-box;
            width:100%;
            outline:none;
            margin:0;

             &::-moz-placeholder {
                color:#c3ddad;
                opacity:1;
             }
             &::-webkit-input-placeholder {
                color:#c3ddad;
             }
             &:-ms-input-placeholder {
                color:#c3ddad;
             }
        }
        img.search {
            position:absolute;
            bottom:8px;
            width:27px;
            left:15px;
        }

        &.loading {
            .content {
                text-align:center;
                position:relative;
                > img {
                    display:inline;
                    position:absolute;
                    top:calc(50% - 31px);
                    left:calc(50% - 31px);
                }
                ul {
                    display:none;
                }
            }
        }
        &#addons-perPackage-searchable-list {
            .content {
                height:250px;
            }
        }
        &#endisrem-searchable-list .content {
            height:200px;
        }
        &.whiteInput {
            .input-wrapper {
                border:none;
                color:$white;
                background: $higreen;
                box-sizing:border-box;
                width:100%;
                outline:none;
                margin:0;
                padding-top:2px;
                input {
                  background:  white;
                    border-radius: 5px;
                    color: #333;
                    margin: 5px 5px 5px 60px;
                    padding: 5px;
                    width: calc(100% - 65px);
                }
            }
        }
    }

    .foldingBox {
        margin-bottom:20px;

        border:2px solid $black;
        &.loading {
            .content .wrapper {

                text-align:center;
                * {
                    display:none;
                }
                img.loading {
                    margin:30px auto;
                    display:inline;
                }
            }
        }
        img.loading {
            display:none;
        }
        .header {
            margin:0;
            position:relative;
            background:$black;
            color:$higreen;
            padding:10px;
            font-size:20px;
            cursor:pointer;
            font-family:din;
                -moz-user-select: -moz-none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                -o-user-select: none;
                user-select: none;

            &:before {
                content:"";
                border-top:9px solid $higreen;
                border-left:10px solid transparent;
                border-right:10px solid transparent;
                right: 25px;
                position: absolute;
                top: 18px;
            }
            &.open:before {
                transform: rotate(180deg);
                top:15px;
            }
        }
        .content {
            overflow:hidden;
            max-height:0;
            transition:max-height 1s;
            .wrapper {
                padding:20px;
                button {
                    margin:40px auto;
                    padding:10px 15px;
                }
            }
        }
    }

    #addPresetLink, #addReferenceLink, #orderDomain {
        margin:20px 0;
        display:inline-block;
        div{
            display:none;
        }
        &:hover, &.hover {
            div {
                text-align: left;
                text-transform:none;
                font-weight:normal;
                background:$white;
                border:2px solid $higreen;
                position:absolute;
                display:block;
                width:300px;
                color:$dgray;
                height:auto;
                left:0;
                border-radius:10px;
                padding:10px;
                font-size:14px;
                font-family:DIN;
                bottom:65px;
                &:before {
                    border-left: 13px solid transparent;
                    border-right: 13px solid transparent;
                    border-top: 10px solid $higreen;
                    bottom: -12px;
                    content: "";
                    left: 46px;
                    position: absolute;
                }
            }
        }
    }
    #orderDomain {
        float:right;
    }

    button {
        background: $black;
        color:$green;
        font-size:35px;
        font-family:inherit;
        border-radius:0;
        font-family:"dinmedium";
        font-weight:bold;
        padding:16px 10px 10px;
        display:block;
        box-sizing:border-box;
        margin:40px auto;
        text-shadow:none;

        img {
            width:50%;
        }
        &.new {

            background: $higreen;
            border-radius:5px;
            color:white;
            text-transform:uppercase;
            font-size:15px;
            font-family:"dinmedium";
            padding:6px 14px;
            display:inline-block;
            box-sizing:border-box;
            margin:0;
            text-shadow:none;
            height:32px;
            box-shadow: 0 4px 0 $dgreen;
            &.dropdown {
                background: $lgray;
                color:$dgray;
                box-shadow: 0 4px 0 $gray;
                svg {
                    fill: $dgray;
                }
            }
            &.manage {
                padding-top:11px;
                a {
                    color:white !important;
                }
            }
            &.endisable {
                position:relative;
                box-shadow:none;
                font-size:14px;
                width:110px;
                padding-right:40px;
                background:$higreen;

                &.dis {
                    background:$red;
                    padding-right:14px;
                    padding-left:40px;
                    .disable {
                        display:inline-block;
                    }
                    .enable {
                        display:none;
                    }
                    .handle {
                        right:calc(100% - 27px);
                    }
                }
                &:active {
                    top:0;
                }
                .disable {
                    display:none;
                }
                .handle {
                    top:0;
                    bottom:0;
                    right:0;
                    border:1px solid $lgray;
                    background:$lgray;
                    border-radius:5px;
                    background: linear-gradient(to bottom, #ffffff 0%,#f1f1f1 50%,#e1e1e1 51%,#f6f6f6 100%);
                    position:absolute;
                    width:25px;
                    transition:right 0.3s;
                }

            }
            &:active {
                box-shadow:none;
                position:relative;
                top:4px;
            }
            span {
                padding-top:3px;
                display:inline-block;
            }
            svg, img {
                width:15px;
                vertical-align:middle;
                margin-left:18px;
                position:relative;
                top:-2px;
                fill:white;
            }

        }

        &.grey_ {
            color:$grey;
        }
        &.btn_saveConfig, &.btn_savePreset {
            height:42px;
            display:block;
            img {
                display:none;
            }
            span {
                color:#fff !important;
                padding-top:0;

            }
            &.loading {
               span {
                   display:none;
               }
               img {
                   display:inline;
                   width:auto;
                   margin-right:18px;
               }
            }
        }
        &#btn_addPreset {
            margin:25px 0;

        }
    }
    #popup-wrapper {
        top:0;
        left:0;
        position:fixed;
        width:100%;
        height:100%;
        z-index:2;
        display:none;
        .mask {
            background:$white;
            opacity:0.75;
            width:100%;
            height:100%;
        }
        div:not(.mask) {
            position:absolute;
            width:700px;
            box-sizing:border-box;
            padding:0;
            height:176px;
            border:4px solid $higreen;
            background:white;
            top:calc(35% - 88px);
            left:calc(50% - 350px);
            text-align:center;
            display:none;
            * {
                font-size:25px;
                }
            button {
                font-size:22px;
                padding: 10px 10px 7px;
                height:auto;
            }
        }
        .addReference {
            img {
                display:none;
            }
            input[type="text"] {
                background:none;
                border:none;
                border-bottom:1px solid $higreen;
                font-family:"dinlight";
                padding:3px;
            }
            &.loading {
                button, p {
                    display:none;
                }
                img {
                  display:inline;
                  position: absolute;
                  top: calc(50% - 31px);
                  left: calc(50% - 31px);
                }
            }
        }
        .removeConfirmation {
            img {
                display:none;
            }
            button {
                display:inline-block;
                margin: 15px 30px;
            }
            &.loading {
                button, p {
                    display:none;
                }
                img {
                  display:inline;
                  position: absolute;
                  top: calc(50% - 31px);
                  left: calc(50% - 31px);
                }
            }
        }
        .addingPackage {
            &.added {
                img {
                    display:none;
                }
                button {
                    display:inline-block;
                    margin: 15px 30px;
                    height:auto;
                }
                p {
                    display:none;
                    &.added {
                        display:block;
                    }
                }
            }
            button, p.added {
                display:none;
            }
        }
    }

    #config-wrapper, #preset-wrapper {
        .content {
            input, select {
                background:none;
                border:none;
                border-bottom:1px solid $higreen;
                font-size:inherit;
                font-family:inherit;
                margin: 0 4px;
                padding: 2px 5px;
                color:$green;
            }
            .half input[type=text],.half input[type=email] {
                width:160px;
            }
            span {
                color:$green;
            }
            button {
                font-size:25px;
                margin-bottom:15px;
            }
            select {
                border:1px solid $higreen;
            }
            div.p {
                font-size: 16px;
                line-height: 24px;
                margin: 2em 0 1em;
                position:relative;
            }
            p.category{
                font-weight:bold;
                font-size:18px;
                margin:10px 0 5px;
            }
        }
    }

    #stats-wrapper {
        text-align:center;
        p {
            font-size:18px;
            text-align:left;
        }
        label {
            font-family:"dinmedium";
        }
        &.loading {
            * {
                display:none;
            }
            > img.loading {
                margin:30px auto;
                display:inline;
            }
        }
        .half.loading img.loading {
            margin:30px auto;
            display:inline;
        }
        img.loading {
            display:none;
        }
        .pieChart-wrapper {
            margin-right:50%;
        }
        .pieChart-legend {
            p {
                font-size:15px;
                line-height:10px;
            }
        }
        path:hover {
            opacity:0.8;
        }
    }

    .HI-slider {
        background: $higreen;
        border-radius:200px;
        position:relative;
        height:6px;
        margin-bottom: 30px;
        cursor:pointer;
        .handle {
            border:3px solid $black;
            background:$higreen;
            position:absolute;
            top:-8px;
            width:15px;
            height:15px;
            border-radius:20px;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .checkBoxWrapper {
        position:relative;
        display:inline-block;
        vertical-align:top;
        position:relative;
        top:-11px;
        margin-right:5px;
    }
    .checkBoxWrapper input[type="checkbox"] {
        opacity:0;
        position:absolute;
        top:0;
        left:0;
        margin:0;
    }

    .upgradeBox {
        border:2px solid $black;
        position:relative;
        padding:15px 15px 70px 15px;
        button {
            bottom: 0;
            font-size: 25px;
            margin: 0;
            position: absolute;
            width: 100%;
            left:0;
            padding-bottom:4px;
            img {
                width:auto;
                vertical-align:middle;
                position:relative;
                top:-2px;
            }
            img.loading {
                display:none;
            }
            &:active {
                position:absolute;
                left:0;
                top:auto;
            }
            &.loading {
                img {
                    display:none;
                    &.loading {
                        display:inline;
                        margin:5px;
                    }
                }
            }
        }
        p {
            font-size:18px;
            &.price {
                font-size:22px;
                font-family:"dinmedium";
            }
        }
        label {
            color:$higreen;
            font-family:"dinmedium";
        }
        select {
            font-family: inherit;
            font-size: inherit;
            margin: 0 5px;
            padding: 0 3px;
            border:none;
            border-bottom:1px solid $higreen;
        }
    }

    #has-no-services, #has-services .boxTitle {
        a {
            color:$blue;
            font-weight:bold;
        }
    }


    .tooltip {
        background: $higreen;
        border-radius: 37px;
        color: white;
        display: inline-block;
        font-size: 15px;
        height: 20px;
        line-height: 20px;
        width: 21px;
        margin-left:4px;
        position:relative;
        &:hover {
            div {
                display:block;
            }
        }
        div {
            text-align: left;
            background:$white;
            border:2px solid $higreen;
            position:absolute;
            display:none;
            width:300px;
            color:$dgray;
            height:auto;
            left:-50px;
            border-radius:10px;
            padding:10px;
            font-size:14px;
            bottom:35px;
            &:before {
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-top: 10px solid $higreen;
                bottom: -12px;
                content: "";
                left: 46px;
                position: absolute;
            }
        }
    }

    #addPackageUI .half.right, #btn_addPackage {
        display:none;
    }
    #btn_addPackage {
        font-size: 40px;
        height: auto;
        margin: 30px auto;
        padding: 10px 20px;
    }
    .presetSelected #btn_addPackage {
        display:block;
    }
    .domainSelected #addPackageUI .half.right {
        display:inline-block;
    }
    .nonResellerOnly {
        display:none;
    }
    .nonReseller {
        p.nonResellerOnly {
            display:block;
        }
        svg.nonResellerOnly {
            display:inline;
        }
        #addPackageUI {
            .half {
                width:100%;
            }
        }
        #packagesList {
            th:nth-child(3), td:nth-child(3) {
                display:none;
            }
        }
    }

    #show-add-ui {
        margin-right:20px;
        &+a button.new {
            img {
                width:20px;
                margin:-5px -5px -5px 13px;
            }
        }
    }
}
